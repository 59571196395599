import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';

import { ToggleDarkModeComponent } from './toggle-dark-mode/toggle-dark-mode.component';
import { TabsComponent } from './tabs/tabs.component';
import { FriendItemComponent } from './friend-item/friend-item.component';

@NgModule({
    declarations: [ToggleDarkModeComponent, TabsComponent, FriendItemComponent],
    imports: [CommonModule, IonicModule, RouterModule],
    exports: [ToggleDarkModeComponent, TabsComponent, FriendItemComponent],
})
export class ComponentsModule {}
