/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import * as Realm from 'realm-web';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    app = new Realm.App({ id: environment.realmAppID });
    mongo;

    constructor() {}

    async initMongo() {
        if (this.app.currentUser) {
            this.mongo = await this.app.currentUser.mongoClient(
                'mongodb-atlas'
            );
        }
    }
}
