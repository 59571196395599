/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { StorageService } from './storage.service';

import {
    User,
    Friend,
    FoundUser,
    BulkWriteResponse,
    BulkWriteError,
} from '../interfaces/interfaces';
import { ObjectID } from 'bson';
import { ThemeService } from './theme.service';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    db;
    usersCollection;
    oid: ObjectID;

    friendStatus = {
        accepted: 'accepted',
        received: 'received',
        sent: 'sent',
        rejected: 'rejected',
        blocked: 'blocked',
    };

    userEmitter = new BehaviorSubject<User>(this.currentUser);

    private _currentUser: User;

    constructor(
        private storageService: StorageService,
        private themeService: ThemeService
    ) {
        this.init();
    }

    get currentUser(): User {
        return this._currentUser;
    }

    set currentUser(user: User) {
        if (user) {
            this._currentUser = user;
            this.oid = new ObjectID(user._id);
        } else {
            this._currentUser = null;
            this.oid = null;
        }

        this.userEmitter.next(this._currentUser);
    }

    async init() {
        if (!this.storageService.mongo) {
            await this.storageService.initMongo();
        }

        if (this.storageService.app.currentUser) {
            this.db = this.storageService.mongo.db('xatsapp');
            this.usersCollection = this.db.collection('users');
        }
    }

    async getCurrentUser() {
        this.currentUser =
            (await this.storageService.app.currentUser.refreshCustomData()) as unknown as User;

        if (
            this.themeService.currentDarkmode !==
            this.currentUser.settings.darkmode
        ) {
            this.themeService.updateTheme(this.currentUser.settings.darkmode);
        }
    }

    async getUserByID(oid: string) {
        return await this.db.collection('users').findOne({ _id: oid });
    }

    async updateUser(user) {
        return await this.db.collection('users').updateOne(
            { _id: new ObjectID(user._id) },
            {
                $set: { name: user.name },
            }
        );
    }

    async searchUser(search: string): Promise<FoundUser[]> {
        return new Promise<FoundUser[]>((resolve, reject) => {
            this.db
                .collection('users')
                .aggregate([
                    // Dont include current user in the results
                    {
                        $match: {
                            _id: {
                                $not: {
                                    $eq: this.oid,
                                },
                            },
                        },
                    },
                    // Search by name and email
                    {
                        $match: {
                            $or: [
                                {
                                    name: {
                                        $regex: search,
                                        $options: 'i',
                                    },
                                },
                                { email: { $regex: search, $options: 'i' } },
                            ],
                        },
                    },
                    // Project the data we need and status
                    {
                        $project: {
                            _id: 1,
                            name: 1,
                            avatar: 1,
                            status: {
                                accepted: {
                                    $in: [
                                        this.oid,
                                        {
                                            $map: {
                                                input: '$friends',
                                                as: 'friend',
                                                in: {
                                                    $cond: {
                                                        if: {
                                                            $eq: [
                                                                '$$friend.status',
                                                                'accepted',
                                                            ],
                                                        },
                                                        then: '$$friend._id',
                                                        else: null,
                                                    },
                                                },
                                            },
                                        },
                                    ],
                                },
                                sent: {
                                    $in: [
                                        this.oid,
                                        {
                                            $map: {
                                                input: '$friends',
                                                as: 'friend',
                                                in: {
                                                    $cond: {
                                                        if: {
                                                            $eq: [
                                                                '$$friend.status',
                                                                'received',
                                                            ],
                                                        },
                                                        then: '$$friend._id',
                                                        else: null,
                                                    },
                                                },
                                            },
                                        },
                                    ],
                                },
                                received: {
                                    $in: [
                                        this.oid,
                                        {
                                            $map: {
                                                input: '$friends',
                                                as: 'friend',
                                                in: {
                                                    $cond: {
                                                        if: {
                                                            $eq: [
                                                                '$$friend.status',
                                                                'sent',
                                                            ],
                                                        },
                                                        then: '$$friend._id',
                                                        else: null,
                                                    },
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                    },
                ])
                .then((users) => {
                    resolve(users);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    // Friend requests
    async sendRequest(friend: Friend): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            await this.storageService.app.currentUser
                .callFunction('sendFriendRequest', this.oid, friend._id)
                .then((response: BulkWriteResponse) =>
                    resolve(response.success)
                )
                .catch((error: BulkWriteError) => reject(error));
        });
    }

    async acceptRequest(friend: Friend): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            await this.storageService.app.currentUser
                .callFunction('acceptFriendRequest', this.oid, friend._id)
                .then((response: boolean) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    async toggleFavorite(
        friend: Friend,
        isFavorite: boolean
    ): Promise<boolean> {
        return new Promise(async (resolve) => {
            await this.usersCollection
                .updateOne(
                    {
                        _id: this.oid,
                        friends: { $elemMatch: { _id: friend._id } },
                    },
                    {
                        $set: { 'friends.$.isFavorite': isFavorite },
                    }
                )
                .then(() => {
                    resolve(true);
                })
                .catch(() => {
                    resolve(false);
                });
        });
    }

    async rejectRequest(friend: Friend): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            await this.storageService.app.currentUser
                .callFunction('rejectFriendRequest', this.oid, friend._id)
                .then((response: boolean) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    async cancelRequest(friend: Friend): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            await this.storageService.app.currentUser
                .callFunction('cancelFriendRequest', this.oid, friend._id)
                .then((response: boolean) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    async deleteFriend(friend: Friend): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            await this.storageService.app.currentUser
                .callFunction('deleteFriend', this.oid, friend._id)
                .then((response: boolean) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    // Search Users
    async findUserByEmail(email: string): Promise<User> {
        return new Promise(async (resolve, reject) => {
            await this.db
                .collection('users')
                .findOne({ email })
                .then((user: User) => {
                    resolve(user);
                })
                .catch(() => {
                    reject('User not found');
                });
        });
    }

    // Settings
    async toggleDarkMode(darkmode: boolean): Promise<boolean> {
        return new Promise(async (resolve) => {
            this.currentUser.settings.darkmode = darkmode;

            await this.usersCollection
                .updateOne(
                    { _id: this.oid },
                    {
                        $set: {
                            'settings.darkmode':
                                this.currentUser.settings.darkmode,
                        },
                    }
                )
                .then(() => {
                    resolve(true);
                })
                .catch(() => {
                    resolve(false);
                });
        });
    }

    // Logout
    async logout(): Promise<boolean> {
        return new Promise(async (resolve) => {
            await this.storageService.app.currentUser
                .logOut()
                .then(() => {
                    this.storageService.mongo = null;
                    this.currentUser = null;
                    resolve(true);
                })
                .catch(() => resolve(false));
        });
    }
}
