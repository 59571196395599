import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Friend } from 'src/app/interfaces/interfaces';
import { UserService } from '../../services/user.service';
import { FriendStatus } from '../../interfaces/interfaces';
import { ActionSheetController, AlertController } from '@ionic/angular';

@Component({
    selector: 'app-friend-item',
    templateUrl: './friend-item.component.html',
    styleUrls: ['./friend-item.component.scss'],
})
export class FriendItemComponent {
    @Input() friend: Friend;
    @Input() status: string;

    @Output() requestAcceptedEvent = new EventEmitter<Friend>();
    @Output() addFavoriteEvent = new EventEmitter<Friend>();
    @Output() removeFavoriteEvent = new EventEmitter<Friend>();
    @Output() requestRejectedEvent = new EventEmitter<Friend>();
    @Output() requestCancelledEvent = new EventEmitter<Friend>();
    @Output() deleteFriendEvent = new EventEmitter<Friend>();
    @Output() startChatEvent = new EventEmitter<Friend>();

    friendStatus: FriendStatus;

    constructor(
        private userService: UserService,
        private alertController: AlertController,
        private actionSheetCtrl: ActionSheetController
    ) {
        this.friendStatus = this.userService.friendStatus;
    }

    startChat() {
        this.startChatEvent.emit(this.friend);
    }

    addFavorite() {
        this.addFavoriteEvent.emit(this.friend);
    }

    removeFavorite() {
        this.removeFavoriteEvent.emit(this.friend);
    }

    acceptFriendRequest() {
        this.requestAcceptedEvent.emit(this.friend);
    }

    rejectFriendRequest() {
        this.requestRejectedEvent.emit(this.friend);
    }

    cancelFriendRequest() {
        this.requestCancelledEvent.emit(this.friend);
    }

    async deleteFriend() {
        const alert = await this.alertController.create({
            header: 'Delete contact!',
            message: 'Are you sure you want to delete this contact?',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    handler: () => {},
                },
                {
                    text: 'OK',
                    role: 'confirm',
                    handler: () => {
                        this.deleteFriendEvent.emit(this.friend);
                    },
                },
            ],
        });

        await alert.present();

        const { role } = await alert.onDidDismiss();
    }

    async presentActionSheet(friend: Friend, status: string) {
        const buttons = [];

        switch (status) {
            case this.friendStatus.accepted: {
                buttons.push({
                    text: 'Send message',
                    icon: 'chatbubble-outline',
                    data: {
                        action: 'startChat',
                    },
                });

                if (friend.isFavorite) {
                    buttons.push({
                        text: 'Remove from favorites',
                        icon: 'star',
                        data: {
                            action: 'removeFavorite',
                        },
                    });
                } else {
                    buttons.push({
                        text: 'Add to favorites',
                        icon: 'star-outline',
                        data: {
                            action: 'addFavorite',
                        },
                    });
                }

                buttons.push({
                    text: 'Delete friend',
                    icon: 'trash-outline',
                    role: 'destructive',
                    data: {
                        action: 'deleteFriend',
                    },
                });

                break;
            }

            case this.friendStatus.received: {
                buttons.push(
                    {
                        text: 'Accept',
                        icon: 'checkmark-outline',
                        data: {
                            action: 'acceptFriendRequest',
                        },
                    },
                    {
                        text: 'Reject',
                        icon: 'close-outline',
                        role: 'destructive',
                        data: {
                            action: 'rejectFriendRequest',
                        },
                    }
                );
                break;
            }

            case this.friendStatus.sent: {
                buttons.push({
                    text: 'Remove friend request',
                    icon: 'trash-outline',
                    role: 'destructive',
                    data: {
                        action: 'cancelFriendRequest',
                    },
                });
                break;
            }
        }

        buttons.push({
            text: 'Cancel',
            role: 'cancel',
            data: {
                action: 'cancel',
            },
        });

        const actionSheet = await this.actionSheetCtrl.create({
            header: friend.name,
            buttons,
        });

        await actionSheet.present();

        const result = await actionSheet.onDidDismiss();

        if (result.data) {
            this.handleActionSheet(result.data.action);
        }
    }

    handleActionSheet(action) {
        if (action !== 'cancel') {
            this[action]();
        }
    }
}
