import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    currentDarkmode: boolean;

    constructor() {}

    updateTheme(darkmode: boolean) {
        document.body.classList.toggle('dark', darkmode);
        this.currentDarkmode = darkmode;
    }
}
