// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    url: 'http://192.168.1.63:3021',
    realmAppID: 'application-0-lvgnm',
    firebaseConfig: {
        apiKey: 'AIzaSyAKNzRIoak9fmPjYbfFynmxD18A_OgOUXo',
        authDomain: 'xatsapp.firebaseapp.com',
        projectId: 'xatsapp',
        storageBucket: 'xatsapp.appspot.com',
        messagingSenderId: '669044589860',
        appId: '1:669044589860:web:368b31575d297cf1231fb1',
        measurementId: 'G-CXRSPG38ET',
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
