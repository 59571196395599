/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import { ObjectID } from 'bson';
import { UserService } from './user.service';

import { Platform } from '@ionic/angular';
import {
    ActionPerformed,
    PushNotificationSchema,
    PushNotifications,
    Token,
} from '@capacitor/push-notifications';

import { Capacitor } from '@capacitor/core';

@Injectable({
    providedIn: 'root',
})
export class PushService {
    app;
    messaging;

    constructor(private userService: UserService, private platform: Platform) {}

    init() {
        this.app = initializeApp(environment.firebaseConfig);
        this.messaging = getMessaging(this.app);
    }

    initNotifications() {
        const isPushNotificationsAvailable =
            Capacitor.isPluginAvailable('PushNotifications');

        if (isPushNotificationsAvailable) {
            if (this.platform.is('android')) {
                alert('Initializing iOS and android push notifications');

                // Request permission to use push notifications
                // iOS will prompt user and return if they granted permission or not
                // Android will just grant without prompting
                PushNotifications.requestPermissions().then((result) => {
                    if (result.receive === 'granted') {
                        // Register with Apple / Google to receive push via APNS/FCM
                        PushNotifications.register();
                    } else {
                        // Show some error
                    }
                });

                // On success, we should be able to receive notifications
                PushNotifications.addListener(
                    'registration',
                    async (token: Token) => {
                        await this.saveFCMToken(token.value);
                    }
                );

                // Some issue with our setup and push will not work
                PushNotifications.addListener(
                    'registrationError',
                    (error: any) => {
                        alert(
                            'Error on registration: ' + JSON.stringify(error)
                        );
                    }
                );

                // Show us the notification payload if the app is open on our device
                PushNotifications.addListener(
                    'pushNotificationReceived',
                    (notification: PushNotificationSchema) => {
                        alert('Push received: ' + JSON.stringify(notification));
                    }
                );

                // Method called when tapping on a notification
                PushNotifications.addListener(
                    'pushNotificationActionPerformed',
                    (notification: ActionPerformed) => {
                        alert(
                            'Push action performed: ' +
                                JSON.stringify(notification)
                        );
                    }
                );
            } else if (this.platform.is('pwa')) {
                alert('Initializing PWA push notifications');
                this.requestPWAPermission();
            }
        }
    }

    requestPWAPermission() {
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                this.getCurrentToken();
            }
        });
    }

    getCurrentToken() {
        getToken(this.messaging, {
            vapidKey:
                'BJVgSMRVC6Befp6-BWcaEHldLRATqKxwGbTUWCcozpjUMPV-d2JXbEt2P8mw5QZi9HZaS5H29EhEiGwm40-l0dA',
        })
            .then(async (currentToken) => {
                console.log(currentToken);
                if (currentToken) {
                    await this.saveFCMToken(currentToken);
                } else {
                    // Show permission request UI
                    console.log(
                        'No registration token available. Request permission to generate one.'
                    );
                }
            })
            .catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
            });
    }

    async saveFCMToken(token: string) {
        return await this.userService.usersCollection.updateOne(
            { _id: new ObjectID(this.userService.currentUser._id) },
            {
                $set: { fcm: token },
            }
        );
    }
}
