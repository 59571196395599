import { Injectable } from '@angular/core';
import {
    AlertController,
    LoadingController,
    ToastController,
} from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class UiService {
    loading: HTMLIonLoadingElement;

    constructor(
        private toastController: ToastController,
        private alertController: AlertController,
        private loadingCtrl: LoadingController
    ) {}

    async presentToast(message: string, duration: number, color?: string) {
        const toast = await this.toastController.create({
            message,
            duration,
            color,
        });
        toast.present();
    }

    async presentSimpleAlert(header: string, message: string) {
        const alert = await this.alertController.create({
            header,
            message,
            buttons: ['OK'],
        });

        await alert.present();
    }

    async showLoading(message: string) {
        this.loading = await this.loadingCtrl.create({
            message,
        });

        this.loading.present();
    }

    closeLoading() {
        this.loading.dismiss();
    }
}
