import {
    Component,
    ViewChild,
    OnInit,
    Input,
    AfterViewInit,
} from '@angular/core';
import { IonToggle } from '@ionic/angular';
import { ThemeService } from '../../services/theme.service';
import { UserService } from '../../services/user.service';
import { UiService } from '../../services/ui.service';
import { User } from 'src/app/interfaces/interfaces';

@Component({
    selector: 'app-toggle-dark-mode',
    templateUrl: './toggle-dark-mode.component.html',
    styleUrls: ['./toggle-dark-mode.component.scss'],
})
export class ToggleDarkModeComponent implements OnInit, AfterViewInit {
    @Input() user: User;
    @ViewChild('themeToggle') themeToggle: IonToggle;

    prefersDark: MediaQueryList = window.matchMedia(
        '(prefers-color-scheme: dark)'
    );

    constructor(
        private themeService: ThemeService,
        private userService: UserService,
        private uiService: UiService
    ) {}

    ngOnInit() {
        if (this.user.settings.darkmode === undefined) {
            this.checkSystemPreferences();
        }
    }

    ngAfterViewInit(): void {
        this.themeToggle.checked = this.user.settings.darkmode;
    }

    checkSystemPreferences() {
        this.checkToggle(this.prefersDark);

        this.prefersDark.addEventListener('change', (event) => {
            this.checkToggle(event);
        });
    }

    async toggleDarkMode(event) {
        const darkmode = event.detail.checked;

        const darkmodeToggled = await this.userService.toggleDarkMode(darkmode);

        if (!darkmodeToggled) {
            const message = `There was an error saving your color preferences into your profile.
                Darkmode will be still changed but not saved for the next time`;
            this.uiService.presentToast(message, 2500, 'danger');
        }

        this.themeService.updateTheme(darkmode);
    }

    checkToggle(event) {
        if (event.matches) {
            this.user.settings.darkmode = true;
        } else {
            this.user.settings.darkmode = false;
        }
    }

    updateTheme(darkmode: boolean) {
        this.themeService.updateTheme(darkmode);
    }
}
