import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { TabsComponent } from './components/tabs/tabs.component';

const routes: Routes = [
    {
        path: 'login',
        loadChildren: () =>
            import('./pages/login/login.module').then((m) => m.HomePageModule),
    },
    {
        path: 'register',
        loadChildren: () =>
            import('./pages/register/register.module').then(
                (m) => m.RegisterPageModule
            ),
    },
    {
        path: 'tabs',
        component: TabsComponent,
        children: [
            {
                path: 'contacts',
                loadChildren: () =>
                    import('./pages/contacts/contacts.module').then(
                        (m) => m.ContactsPageModule
                    ),
            },
            {
                path: 'chats',
                loadChildren: () =>
                    import('./pages/chats/chats.module').then(
                        (m) => m.ChatsPageModule
                    ),
            },
            {
                path: 'settings',
                loadChildren: () =>
                    import('./pages/settings/settings.module').then(
                        (m) => m.SettingsPageModule
                    ),
            },
        ],
    },
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
    },
    {
        path: 'confirmEmail',
        loadChildren: () =>
            import('./confirm-email/confirm-email.module').then(
                (m) => m.ConfirmEmailPageModule
            ),
    },
    {
        path: 'profile',
        loadChildren: () =>
            import('./profile/profile.module').then((m) => m.ProfilePageModule),
    },
    {
        path: 'chat/:id',
        loadChildren: () =>
            import('./pages/chat/chat.module').then((m) => m.ChatPageModule),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
